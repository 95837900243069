import { Card, Heading, Box, Flex, Button, Link } from "theme-ui";

import { GT } from "../../strings";

import { InfoMessage } from "../InfoMessage";
import { Icon } from "../Icon";
import { useStakingView } from "./context/StakingViewContext";

export const NoStake: React.FC = () => {
  const { dispatch } = useStakingView();

  return (
    <Card>
      <Heading>
        <div>
          <Icon name="coins" style={{ marginRight: '12px' }} />
          Staking
        </div>
      </Heading>
      <Box sx={{ p: [2, 3] }}>
        <InfoMessage title={`You haven't staked ${GT} yet.`} icon={<Icon name="info-circle" />}>
          Stake {GT} to earn a share of protocol fees in ETH and LUSD. Learn more: <Link href="https://docs.liquity.org/faq/staking#how-does-staking-work-in-liquity" target="_blank">How does Staking work? <Icon name="external-link-alt" /></Link>
        </InfoMessage>

        <Flex variant="layout.actions">
          <Button onClick={() => dispatch({ type: "startAdjusting" })}>Start staking</Button>
        </Flex>
      </Box>
    </Card>
  );
};
