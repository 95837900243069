import { useEffect, useState } from "react";
import { AddressZero } from "@ethersproject/constants";
import {  Container } from "theme-ui";


import { LiquityStoreState } from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";

import { useLiquity } from "../hooks/LiquityContext";

import { Dashboard } from "./Dashboard";
import { UnregisteredFrontend } from "./UnregisteredFrontend";
import { FrontendRegistration } from "./FrontendRegistration";
import { FrontendRegistrationSuccess } from "./FrontendRegistrationSuccess";

const selectFrontend = ({ frontend }: LiquityStoreState) => frontend;

export const PageSwitcher: React.FC = () => {
  const {
    account,
    config: { frontendTag },
  } = useLiquity();

  const frontend = useLiquitySelector(selectFrontend);
  const unregistered =
    frontendTag !== AddressZero && frontend.status === "unregistered";

  const [registering, setRegistering] = useState(false);

  useEffect(() => {
    if (unregistered) {
      setRegistering(true);
    }
  }, [unregistered]);

  if (registering || unregistered) {
    if (frontend.status === "registered") {
      return (
        <Container
          variant="main"
          sx={{
            display: "flex",
            flexGrow: 1,
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <FrontendRegistrationSuccess
            onDismiss={() => setRegistering(false)}
          />
          ;
        </Container>
      );
    } else if (account === frontendTag) {
      return (
        <Container
          variant="main"
          sx={{
            display: "flex",
            flexGrow: 1,
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <FrontendRegistration />;
        </Container>
      );
    } else {
      return (
        <Container
          variant="main"
          sx={{
            display: "flex",
            flexGrow: 1,
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <UnregisteredFrontend />;
        </Container>
      );
    }
  } else {
    return <Dashboard />;
  }
};
