import React, { useEffect, useReducer, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { AbstractConnector } from "@web3-react/abstract-connector";
import { Button, Text, Link, Box } from "theme-ui";

import { injectedConnector } from "../connectors/injectedConnector";
import { makeWalletConnectConnector } from "../connectors/walletConnectConnector";
import { makeWalletLinkConnector } from "../connectors/walletLinkConnector";
import { useAuthorizedConnection } from "../hooks/useAuthorizedConnection";

import { RetryDialog } from "./RetryDialog";
import { ConnectionConfirmationDialog } from "./ConnectionConfirmationDialog";
import { MetaMaskIcon } from "./MetaMaskIcon";
import { Icon } from "./Icon";
import { Modal } from "./Modal";
import { LiquityLogo } from "./LiquityLogo";

import { LiquityFrontendConfig, getConfig } from "../config";

interface MaybeHasMetaMask {
  ethereum?: {
    isMetaMask?: boolean;
  };
}

type ConnectionState =
  | { type: "inactive" }
  | {
      type:
        | "activating"
        | "active"
        | "rejectedByUser"
        | "alreadyPending"
        | "failed";
      connector: AbstractConnector;
    };

type ConnectionAction =
  | { type: "startActivating"; connector: AbstractConnector }
  | { type: "fail"; error: Error }
  | { type: "finishActivating" | "retry" | "cancel" | "deactivate" };

const connectionReducer: React.Reducer<ConnectionState, ConnectionAction> = (
  state,
  action
) => {
  switch (action.type) {
    case "startActivating":
      return {
        type: "activating",
        connector: action.connector,
      };
    case "finishActivating":
      return {
        type: "active",
        connector:
          state.type === "inactive" ? injectedConnector : state.connector,
      };
    case "fail":
      if (state.type !== "inactive") {
        return {
          type: action.error.message.match(/user rejected/i)
            ? "rejectedByUser"
            : action.error.message.match(/already pending/i)
            ? "alreadyPending"
            : "failed",
          connector: state.connector,
        };
      }
      break;
    case "retry":
      if (state.type !== "inactive") {
        return {
          type: "activating",
          connector: state.connector,
        };
      }
      break;
    case "cancel":
      return {
        type: "inactive",
      };
    case "deactivate":
      return {
        type: "inactive",
      };
  }

  console.warn("Ignoring connectionReducer action:");
  console.log(action);
  console.log("  in state:");
  console.log(state);

  return state;
};

const detectMetaMask = () =>
  (window as MaybeHasMetaMask).ethereum?.isMetaMask ?? false;

type WalletConnectorProps = {
  loader?: React.ReactNode;
};

const makeInfuraUrl = (network: string, apiKey: string) =>
  `https://${network}.infura.io/v3/${apiKey}`;

export const WalletConnector: React.FC<WalletConnectorProps> = ({
  children,
  loader,
}) => {
  const { activate, deactivate, active, error } = useWeb3React<unknown>();
  const triedAuthorizedConnection = useAuthorizedConnection();
  const [connectionState, dispatch] = useReducer(connectionReducer, {
    type: "inactive",
  });
  const isMetaMask =
    connectionState.type === "inactive"
      ? detectMetaMask()
      : connectionState.connector === injectedConnector && detectMetaMask();

  const [config, setConfig] = useState<LiquityFrontendConfig>();

  useEffect(() => {
    getConfig().then(setConfig);
  }, []);

  useEffect(() => {
    if (error) {
      dispatch({ type: "fail", error });
      deactivate();
    }
  }, [error, deactivate]);

  useEffect(() => {
    if (active) {
      dispatch({ type: "finishActivating" });
    } else {
      dispatch({ type: "deactivate" });
    }
  }, [active]);

  if (!config) {
    return <>{loader}</>;
  }

  let walletLinkConnector: AbstractConnector;
  let walletConnectConnector: AbstractConnector;
  let infuraAvailable = false;
  if (config.infuraApiKey) {
    walletLinkConnector = makeWalletLinkConnector(
      makeInfuraUrl("mainnet", config.infuraApiKey)
    );
    walletConnectConnector = makeWalletConnectConnector(
      makeInfuraUrl("mainnet", config.infuraApiKey)
    );
    infuraAvailable = true;
  }

  if (!triedAuthorizedConnection) {
    return <>{loader}</>;
  }

  if (connectionState.type === "active") {
    return <>{children}</>;
  }

  return (
    <>
      {/* tailwind code start */}
      <div
        className="relative bg-gray-50 overflow-hidden"
        style={{
          height: "100vh",
        }}
      >
        <div
          className="hidden sm:block sm:absolute sm:inset-y-0 sm:h-full sm:w-full"
          aria-hidden="true"
        >
          <div className="relative h-full max-w-7xl mx-auto">
            <svg
              className="absolute right-full transform translate-y-1/4 translate-x-1/4 lg:translate-x-1/2"
              width={404}
              height={784}
              fill="none"
              viewBox="0 0 404 784"
            >
              <defs>
                <pattern
                  id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={784}
                fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
              />
            </svg>
            <svg
              className="absolute left-full transform -translate-y-3/4 -translate-x-1/4 md:-translate-y-1/2 lg:-translate-x-1/2"
              width={404}
              height={784}
              fill="none"
              viewBox="0 0 404 784"
            >
              <defs>
                <pattern
                  id="5d0dd344-b041-4d26-bec4-8d33ea57ec9b"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={784}
                fill="url(#5d0dd344-b041-4d26-bec4-8d33ea57ec9b)"
              />
            </svg>
          </div>
        </div>

        <div style={{ height: "100%"}} className="relative pt-6 pb-16 sm:pb-24">
          <div className="max-w-7xl mx-auto px-4 sm:px-6">
            <nav
              className="relative flex items-center justify-between sm:h-10 md:justify-center"
              aria-label="Global"
            >
              <div className="flex items-center flex-1 md:absolute md:inset-y-0 md:left-0">
                <div className="flex items-center justify-between w-full md:w-auto">
                  <LiquityLogo height={36} />
                </div>
              </div>
            </nav>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            {/* 116,93,223 */}
            <main className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24">
              <div className="text-center">
                <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                  <span className="block text-purple-500 xl:inline">Interest-Free Borrowing</span>{" "}
                  <span className="block xl:inline">
                    at Your Fingertips
                  </span>
                </h1>
                <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
                  <br />Liquity.app is the most widely trusted venue for Liquity Protocol.<br /><br />Draw interest-free LUSD debt against your ETH. Deposit your LUSD into the Stability Pool to earn rewards in LQTY. Deposit LQTY to earn protocol income in LUSD and ETH.
                </p>
                <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      maxWidth: "600px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box pt={4}>
                      <Button
                        sx={{ width: "100%" }}
                        onClick={() => {
                          dispatch({
                            type: "startActivating",
                            connector: injectedConnector,
                          });
                          activate(injectedConnector);
                        }}
                      >
                        {isMetaMask ? (
                          <>
                            <MetaMaskIcon />
                            <Box sx={{ ml: 2 }}>Connect to MetaMask</Box>
                          </>
                        ) : (
                          <>
                            <Icon name="plug" size="lg" />
                            <Box sx={{ ml: 2 }}>Connect to Web3 Wallet</Box>
                          </>
                        )}
                      </Button>
                      {infuraAvailable && (
                        <>
                          <Button
                            sx={{ mt: 3, width: "100%" }}
                            onClick={() => {
                              dispatch({
                                type: "startActivating",
                                connector: walletConnectConnector,
                              });
                              activate(walletConnectConnector);
                            }}
                          >
                            Connect via WalletConnect
                          </Button>
                          <Button
                            sx={{ mt: 3, width: "100%" }}
                            onClick={() => {
                              dispatch({
                                type: "startActivating",
                                connector: walletLinkConnector,
                              });
                              activate(walletLinkConnector);
                            }}
                          >
                            Connect via Coinbase Wallet
                          </Button>
                        </>
                      )}
                    </Box>
                  </Box>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>

      {/* tailwind code end */}
      {connectionState.type === "failed" && (
        <Modal>
          <RetryDialog
            title={
              isMetaMask
                ? "Failed to connect to MetaMask"
                : "Failed to connect wallet"
            }
            onCancel={() => dispatch({ type: "cancel" })}
            onRetry={() => {
              dispatch({ type: "retry" });
              activate(connectionState.connector);
            }}
          >
            <Box sx={{ textAlign: "center" }}>
              You might need to install MetaMask or use a different browser.
            </Box>
            <Link
              sx={{ lineHeight: 3 }}
              href="https://metamask.io/download.html"
              target="_blank"
            >
              Learn more <Icon size="xs" name="external-link-alt" />
            </Link>
          </RetryDialog>
        </Modal>
      )}

      {connectionState.type === "activating" && (
        <Modal>
          <ConnectionConfirmationDialog
            title={
              isMetaMask
                ? "Confirm connection in MetaMask"
                : "Confirm connection in your wallet"
            }
            icon={
              isMetaMask ? <MetaMaskIcon /> : <Icon name="wallet" size="lg" />
            }
            onCancel={() => dispatch({ type: "cancel" })}
          >
            <Text sx={{ textAlign: "center" }}>
              Confirm the request that&apos;s just appeared.
              {isMetaMask ? (
                <>
                  {" "}
                  If you can&apos;t see a request, open your MetaMask extension
                  via your browser.
                </>
              ) : (
                <>
                  {" "}
                  If you can&apos;t see a request, you might have to open your
                  wallet.
                </>
              )}
            </Text>
          </ConnectionConfirmationDialog>
        </Modal>
      )}

      {connectionState.type === "rejectedByUser" && (
        <Modal>
          <RetryDialog
            title="Cancel connection?"
            onCancel={() => dispatch({ type: "cancel" })}
            onRetry={() => {
              dispatch({ type: "retry" });
              activate(connectionState.connector);
            }}
          >
            <Text>
              To use Liquity, you need to connect your Ethereum account.
            </Text>
          </RetryDialog>
        </Modal>
      )}

      {connectionState.type === "alreadyPending" && (
        <Modal>
          <RetryDialog
            title="Connection already requested"
            onCancel={() => dispatch({ type: "cancel" })}
            onRetry={() => {
              dispatch({ type: "retry" });
              activate(connectionState.connector);
            }}
          >
            <Text>
              Please check your wallet and accept the connection request before
              retrying.
            </Text>
          </RetryDialog>
        </Modal>
      )}
    </>
  );
};
